import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import Digital from "../../assets/images/services/digital.jpeg"

const ItSolutionsPage = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="Digital Marketing Outsourcing Company in India"
                description="We have successfully completed $40,000,000 worth of digital marketing projects for more than 5,000 clients in more than 120 countries." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <div className="left-sec">
                        <img src={Digital} alt="Custom Software Development" className="image" />
                    </div>
                    <div className="right-sec">
                        <h1>Digital Marketing Services</h1>
                        <p className="mt-2">
                            Our Digital Marketing services are designed to help businesses reach their target audience, increase brand visibility, and drive conversions. In today's digital age, having a strong online presence is essential for business success, and our team of experts is here to help you achieve your marketing goals.
                        </p>
                    </div>
                </div>

                <div className="service-sub-container">
                    <h3 className="mt-3">Key Services</h3>
                    <ul>
                        <li className="mt-2"><strong> Search Engine Optimization (SEO)</strong>: Improve your website's visibility in search engine results pages and attract organic traffic.</li>
                        <li className="mt-2"> <strong>Pay-Per-Click (PPC) Advertising</strong>: Reach potential customers with targeted ads on search engines and social media platforms.</li>
                        <li className="mt-2"><strong>Social Media Marketing</strong>: Engage with your audience on popular social media platforms and build brand awareness.</li>
                        <li className="mt-2"><strong>Email Marketing</strong>: Nurture leads and retain customers with personalized email campaigns.</li>
                        <li className="mt-2"><strong>Content Marketing</strong>: Create valuable content to attract and engage your target audience and drive website traffic.</li>
                        <li className="mt-2"><strong>Analytics and Reporting</strong>: Monitor campaign performance and track key metrics to optimize marketing efforts and achieve better results.</li>
                    </ul>
                    <h3 className="mt-4">How It Works</h3>
                    <p className="mt-2">
                        Our Digital Marketing services begin with a thorough assessment of your business goals, target audience, and current marketing efforts. Based on our findings, we develop a customized digital marketing strategy tailored to your specific needs and objectives. Our team then implements the strategy, monitors campaign performance, and makes adjustments as needed to ensure optimal results.
                    </p>
                    <h3>Why Choose Us?</h3>
                    <p className="mt-2">
                        With years of experience in digital marketing and a proven track record of success, our team has the expertise and resources to help you achieve your marketing goals. Whether you're a small business looking to increase brand awareness or a large corporation seeking to drive conversions, we're committed to delivering results that exceed your expectations.
                    </p>
                </div>



                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query DgigitalMarketingPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

ItSolutionsPage.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default ItSolutionsPage;
